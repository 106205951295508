import React from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import OutlineButton from '../../common/OutlineButton';
import ColoredButton from '../../common/ColoredButton';
import { emptyOption } from '../../../constants/constants';

export default function UpdateVerificationStatusForm({
  onCancel,
  onSubmitHandler,
}) {
  const { buttonsDisabled, serverError } = useSelector((state) => state.modal);
  const { pipelineStatusesForSelect } = useSelector(
    (state) => state.pipelineStatus
  );

  const onSubmit = (data) => {
    onSubmitHandler({
      verification_pipeline_status_id: data.verificationStatus.id,
      batch_action: 'batch verification_pipeline_status update',
    });
  };

  const { control, handleSubmit } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div translate="no" className={'text-13 my-6 px-1'}>
        <Controller
          name="verificationStatus"
          control={control}
          rules={{ required: true }}
          defaultValue={emptyOption}
          render={({ field }) => (
            <Select
              {...field}
              isDisabled={buttonsDisabled}
              options={[emptyOption].concat(pipelineStatusesForSelect)}
            />
          )}
        />
      </div>
      <p className="text-red-500 text-center h-8 w-60 my-1 text-xs">
        {serverError}
      </p>
      <OutlineButton
        text="Cancel"
        heightClass="h-10"
        onClick={onCancel}
        disabled={buttonsDisabled}
      />
      <ColoredButton
        text="OK"
        heightClass="h-10"
        submit={true}
        disabled={buttonsDisabled}
      />
    </form>
  );
}
