import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import OutlineButton from '../../common/OutlineButton';
import React from 'react';
import ColoredButton from '../../common/ColoredButton';
import { useSelector } from 'react-redux';
import { getMappableElements } from '../../../helpers/selectHelper';

export default function RemoveCodeTagsForm({
  onCancel,
  onSubmitHandler,
  availableCodeTags,
}) {
  const { buttonsDisabled, serverError } = useSelector((state) => state.modal);

  let codeTagWithAll = [...getMappableElements(availableCodeTags)];
  codeTagWithAll.splice(0, 0, { value: 'All', label: 'All', id: -1 });
  const onSubmit = (data) => {
    let codeTagId =
      data.removeCodeTagSelect.id === -1 ? '' : data.removeCodeTagSelect.id;
    onSubmitHandler({
      code_tag_id: codeTagId,
    });
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <form translate="no" onSubmit={handleSubmit(onSubmit)}>
      <div className={'text-13 my-6 px-1'}>
        <Controller
          name="removeCodeTagSelect"
          control={control}
          defaultValue={codeTagWithAll[0]}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              isDisabled={buttonsDisabled}
              options={codeTagWithAll}
            />
          )}
        />
        {errors.removeCodeTagSelect?.type === 'required' && (
          <p className="absolute text-red-500 text-center w-60 my-1 text-xs">
            Code tags are required
          </p>
        )}
        <p className="text-red-500 h-8 text-center w-60 my-1 text-xs">
          {serverError}
        </p>
      </div>
      <OutlineButton
        text="Cancel"
        heightClass="h-10"
        onClick={onCancel}
        disabled={buttonsDisabled}
      />
      <ColoredButton
        text="OK"
        heightClass="h-10"
        submit={true}
        disabled={buttonsDisabled}
      />
    </form>
  );
}
