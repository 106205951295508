import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import useWebSocket from 'react-use-websocket';
import {
  jobDetailsEditSaved,
  loadJobDetails,
} from '../../features/job/jobActions';
import ConfirmationModal from '../common/ConfirmationModal';
import {
  confirmButtonClicked,
  markJobCompletedClicked,
  markJobOnboardClicked,
  modalClosed,
} from '../../features/modal/modalSlice';
import {
  COMPLETE_JOB_MODAL,
  JOB_DETAILS_MODAL,
  ONBOARD_JOB_MODAL,
  INVOICE_JOB_MODAL,
  UNHIDE_JOB_MODAL,
  ONBOARD_JOB_PRACTICE_TYPE_WARNING_MODAL,
  ONBOARD_JOB_NOT_ALLOWED_MODAL,
  COMPLETE_JOB_NOT_ALLOWED_MODAL,
  COMPLETE_JOB_PRACTICE_TYPE_WARNING_MODAL,
} from '../../constants/modals';
import { getError } from '../../helpers/errorHelper';
import JobTableMappings from './JobTableMappings';
import {
  completeJob,
  onboardJob,
  invoiceJobs,
  unhideJob,
} from '../../services/jobServices';
import ErrorAlert from '../common/ErrorAlert';
import JobDetailsModal from '../job/details/JobDetailsModal';
import {
  COMPLETE_JOB_ACTION_LABEL,
  COMPLETE_JOB_PRACTICE_TYPE_MISSING_LABEL,
  COMPLETE_JOB_PRACTICE_TYPE_MISSING_TEXT,
  getCompleteJobDialogText,
  getTimeSpentConfirmationDialogText,
  INVOICE_JOB_ACTION_LABEL,
  INVOICE_JOB_DIALOG_TEXT,
  RATE_TYPE_PER_HOUR,
  READY_TO_ONBOARD_ACTION_LABEL,
  READY_TO_ONBOARD_DIALOG_TEXT,
  READY_TO_ONBOARD_JOB_PRACTICE_TYPE_MISSING_LABEL,
  READY_TO_ONBOARD_JOB_PRACTICE_TYPE_MISSING_TEXT,
  READY_TO_ONBOARD_JOB_PRACTICE_TYPE_MISSING_WARRNING_TEXT,
  READY_TO_ONBOARD_WARNING_DIALOG_IMPORTANT_TEXT,
  UNHIDE_JOB_ACTION_LABEL,
  UNHIDE_JOB_DIALOG_TEXT,
} from '../../constants/jobConstants';
import { jobMappingsAllowedForUser } from '../../helpers/jobsHelper';
import { formatHours } from '../../helpers/jobsTableHelper';
import OutlineButton from '../common/OutlineButton';
import { openViewJobDetails } from '../../features/modal/modalActions';
import ColoredButton from '../common/ColoredButton';

export default function JobMapping() {
  let { jobId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mappingJobDetails, loadingJobDetails, loadingJobDetailsError } =
    useSelector((state) => state.job);
  const modal = useSelector((state) => state.modal);
  const { userInfo, token } = useSelector((state) => state.user);
  const detailsLoaded =
    !loadingJobDetails && mappingJobDetails && !loadingJobDetailsError;
  const [jobMappingAllowed, setJobMappingAllowed] = useState(false);
  const [actionServerError, setActionServerError] = useState(null);

  const onJobRefresh = useCallback(() => {
    //handle onJobRefresh
  });

  const subscribeMsg = useMemo(() => {
    return {
      command: 'subscribe',
      identifier: JSON.stringify({
        channel: 'JobUpdatesChannel',
        mapping_job_id: jobId,
      }),
    };
  }, [token]);

  const { sendJsonMessage } = useWebSocket(
    `${process.env.REACT_APP_SERVER_API_WS_URL}?token=${token}`,
    {
      onOpen: () => sendJsonMessage(subscribeMsg),
      onMessage: onJobRefresh,
    }
  );

  const onboardJobHandler = () => {
    dispatch(confirmButtonClicked());
    onboardJob(jobId)
      .then(({ data }) => {
        dispatch(jobDetailsEditSaved(data));
        navigate('/jobs');
      })
      .catch((err) => {
        setActionServerError(getError(err));
      })
      .finally(() => {
        dispatch(modalClosed());
      });
  };

  const invoiceJobHandler = () => {
    dispatch(confirmButtonClicked());
    invoiceJobs([jobId])
      .then(() => {
        navigate('/jobs');
      })
      .catch((err) => {
        setActionServerError(getError(err));
      })
      .finally(() => {
        dispatch(modalClosed());
      });
  };

  const completeJobHandler = () => {
    dispatch(confirmButtonClicked());
    completeJob(jobId)
      .then(() => {
        navigate('/jobs');
      })
      .catch((err) => {
        setActionServerError(getError(err));
      })
      .finally(() => {
        dispatch(modalClosed());
      });
  };

  const unhideJobHandler = () => {
    dispatch(confirmButtonClicked());
    unhideJob(jobId)
      .then(() => {
        navigate('/jobs');
      })
      .catch((err) => {
        setActionServerError(getError(err));
      })
      .finally(() => {
        dispatch(modalClosed());
      });
  };

  useEffect(() => {
    dispatch(loadJobDetails(jobId));
  }, []);

  useEffect(() => {
    if (mappingJobDetails) {
      setJobMappingAllowed(
        jobMappingsAllowedForUser(mappingJobDetails, userInfo)
      );
    }
  }, [mappingJobDetails]);

  return (
    <div id="mappings" className="flex flex-col h-full flex-grow-1">
      {detailsLoaded && jobMappingAllowed && (
        <Fragment>
          <JobTableMappings jobDetails={mappingJobDetails} />
          <ConfirmationModal
            title={READY_TO_ONBOARD_ACTION_LABEL}
            importantText={
              modal.visibleModalImportantText ||
              READY_TO_ONBOARD_WARNING_DIALOG_IMPORTANT_TEXT
            }
            text={
              modal.visibleModalText ||
              (READY_TO_ONBOARD_DIALOG_TEXT + mappingJobDetails.rate_type ===
              RATE_TYPE_PER_HOUR
                ? getTimeSpentConfirmationDialogText(
                    formatHours(mappingJobDetails.mapped_time)
                  )
                : '')
            }
            buttonText="Yes"
            operationType={modal.visibleModalOperationType || 'warning'}
            onButtonClick={onboardJobHandler}
            onClose={() => dispatch(modalClosed())}
            isOpen={modal.visibleModal === ONBOARD_JOB_MODAL}
            buttonsDisabled={modal.buttonsDisabled}
          />
          <ConfirmationModal
            title={READY_TO_ONBOARD_JOB_PRACTICE_TYPE_MISSING_LABEL}
            text={READY_TO_ONBOARD_JOB_PRACTICE_TYPE_MISSING_TEXT}
            operationType="warning"
            customButtons={() => {
              return (
                <OutlineButton
                  text="Open Job Details"
                  heightClass="h-10"
                  widthClass="w-[150px]"
                  onClick={() =>
                    dispatch(openViewJobDetails(mappingJobDetails.id))
                  }
                />
              );
            }}
            onClose={() => dispatch(modalClosed())}
            isOpen={modal.visibleModal === ONBOARD_JOB_NOT_ALLOWED_MODAL}
          />
          <ConfirmationModal
            title={READY_TO_ONBOARD_JOB_PRACTICE_TYPE_MISSING_LABEL}
            text={READY_TO_ONBOARD_JOB_PRACTICE_TYPE_MISSING_WARRNING_TEXT}
            customButtons={() => {
              return (
                <>
                  <OutlineButton
                    text="Open Job Details"
                    heightClass="h-10"
                    widthClass="w-[150px]"
                    onClick={() =>
                      dispatch(openViewJobDetails(mappingJobDetails.id))
                    }
                  />
                  <ColoredButton
                    text="Complete Anyway"
                    heightClass="h-10"
                    widthClass="w-[150px]"
                    fillColorClass="bg-orange"
                    border="border border-solid border-orange outline-none"
                    hoverColorClass="hover:bg-orange-hover"
                    onClick={() => {
                      dispatch(
                        markJobOnboardClicked({
                          visibleModal: ONBOARD_JOB_MODAL,
                          unverifiedCodesExists: modal.unverifiedCodesExists,
                          allCodesVerified: modal.allCodesVerified,
                          ...(mappingJobDetails.rate_type ===
                            RATE_TYPE_PER_HOUR && {
                            mappedTime: formatHours(
                              mappingJobDetails.mapped_time
                            ),
                          }),
                        })
                      );
                    }}
                  />
                </>
              );
            }}
            onClose={() => dispatch(modalClosed())}
            isOpen={
              modal.visibleModal === ONBOARD_JOB_PRACTICE_TYPE_WARNING_MODAL
            }
          />
          <ConfirmationModal
            title={COMPLETE_JOB_PRACTICE_TYPE_MISSING_LABEL}
            text={COMPLETE_JOB_PRACTICE_TYPE_MISSING_TEXT}
            operationType="warning"
            customButtons={() => {
              return (
                <OutlineButton
                  text="Open Job Details"
                  heightClass="h-10"
                  widthClass="w-[150px]"
                  onClick={() =>
                    dispatch(openViewJobDetails(mappingJobDetails.id))
                  }
                />
              );
            }}
            onClose={() => dispatch(modalClosed())}
            isOpen={modal.visibleModal === COMPLETE_JOB_NOT_ALLOWED_MODAL}
          />
          <ConfirmationModal
            title={COMPLETE_JOB_PRACTICE_TYPE_MISSING_LABEL}
            text={COMPLETE_JOB_PRACTICE_TYPE_MISSING_TEXT}
            customButtons={() => {
              return (
                <>
                  <OutlineButton
                    text="Open Job Details"
                    heightClass="h-10"
                    widthClass="w-[150px]"
                    onClick={() =>
                      dispatch(openViewJobDetails(mappingJobDetails.id))
                    }
                  />
                  <ColoredButton
                    text="Complete Anyway"
                    heightClass="h-10"
                    widthClass="w-[150px]"
                    fillColorClass="bg-orange"
                    border="border border-solid border-orange outline-none"
                    hoverColorClass="hover:bg-orange-hover"
                    onClick={() => dispatch(markJobCompletedClicked())}
                  />
                </>
              );
            }}
            onClose={() => dispatch(modalClosed())}
            isOpen={
              modal.visibleModal === COMPLETE_JOB_PRACTICE_TYPE_WARNING_MODAL
            }
          />
          <ConfirmationModal
            title={COMPLETE_JOB_ACTION_LABEL}
            text={getCompleteJobDialogText()}
            buttonText="Yes"
            onButtonClick={completeJobHandler}
            onClose={() => dispatch(modalClosed())}
            isOpen={modal.visibleModal === COMPLETE_JOB_MODAL}
            buttonsDisabled={modal.buttonsDisabled}
          />
          <ConfirmationModal
            title={INVOICE_JOB_ACTION_LABEL}
            text={INVOICE_JOB_DIALOG_TEXT}
            buttonText="Yes, invoice"
            onButtonClick={invoiceJobHandler}
            onClose={() => dispatch(modalClosed())}
            isOpen={modal.visibleModal === INVOICE_JOB_MODAL}
            buttonsDisabled={modal.buttonsDisabled}
          />
          <ConfirmationModal
            title={UNHIDE_JOB_ACTION_LABEL}
            text={UNHIDE_JOB_DIALOG_TEXT}
            buttonText="Yes, unhide"
            onButtonClick={unhideJobHandler}
            onClose={() => dispatch(modalClosed())}
            isOpen={modal.visibleModal === UNHIDE_JOB_MODAL}
            buttonsDisabled={modal.buttonsDisabled}
          />
          <JobDetailsModal
            onClose={() => dispatch(modalClosed())}
            isOpen={modal.visibleModal === JOB_DETAILS_MODAL}
            title="Details"
          />
        </Fragment>
      )}
      {loadingJobDetailsError && (
        <div className="grid h-full place-items-center">
          {loadingJobDetailsError}
        </div>
      )}
      {mappingJobDetails && !jobMappingAllowed && !loadingJobDetailsError && (
        <div className="grid h-full place-items-center">
          You are not authorized for viewing this job
        </div>
      )}
      {actionServerError && (
        <ErrorAlert
          isOpen={true}
          error={actionServerError}
          onButtonClick={() => setActionServerError(null)}
          onClose={() => setActionServerError(null)}
        />
      )}
    </div>
  );
}
