import {
  allFilters,
  code,
  ENTER_KEY,
  has_transactions,
  mandatoryFilters,
  pms_code_vetsuccess_id,
  verified,
} from '../constants/constants';
import {
  defaultJobsStatusFilter,
  FILTER_ORDER_PER_TYPE,
} from '../constants/jobConstants';
import { MAPPER_ROLE } from '../constants/roles';

const mapTextFilterParams = (filterModel, filter, operator) => {
  const filterParams = [];
  filterModel[filter].values.forEach((v) => {
    if (v.operator === operator) {
      filterParams.push(v.value);
    }
  });
  return filterParams.length ? { [filter]: filterParams.toString() } : null;
};

const mapSortParams = (sortModel) => {
  return {
    sort: {
      sort_key: sortModel.colId,
      sort_direction: sortModel.sort,
    },
  };
};

export const mapFilterParams = (
  gridApi,
  sortModel,
  pageSize,
  pagination_attributes,
  startRow = 0
) => {
  const filterModel = gridApi.getFilterModel();
  return {
    ...(filterModel.practice_id && {
      practice_id: filterModel.practice_id.values,
    }),
    ...(filterModel.revenue_category_id && {
      revenue_category_id: filterModel.revenue_category_id.values,
    }),
    ...(filterModel.description &&
      mapTextFilterParams(filterModel, 'description', 'contains')),
    ...(filterModel.category_description &&
      mapTextFilterParams(filterModel, 'category_description', 'contains')),
    ...(filterModel.code &&
      mapTextFilterParams(filterModel, 'code', 'contains')),
    ...(filterModel.pms_code_vetsuccess_id &&
      mapTextFilterParams(filterModel, 'pms_code_vetsuccess_id', 'equals')),
    ...(filterModel.mapped_by && {
      mapped_by: filterModel.mapped_by.values,
    }),
    ...(filterModel.code_tag_ids && {
      code_tag_id: {
        filter_option: filterModel.code_tag_ids.operator,
        code_tag_ids: filterModel.code_tag_ids.values,
      },
    }),
    ...(filterModel.is_parasiticide && {
      is_parasiticide: filterModel.is_parasiticide.value === 'Yes',
    }),
    ...(filterModel.verified && {
      verified: filterModel.verified.value === 'Yes',
    }),
    ...(filterModel.has_transactions && {
      has_transactions: filterModel.has_transactions.value === 'Yes',
    }),
    ...(filterModel.total_revenue && {
      revenue: {
        filter_option: filterModel.total_revenue.operator,
        filter_number: filterModel.total_revenue.value,
      },
    }),
    ...(filterModel.paid_doses && {
      paid_doses: filterModel.paid_doses.value,
    }),
    ...(filterModel.free_doses && {
      free_doses: filterModel.free_doses.value,
    }),
    ...(filterModel.review_status && {
      review_status: filterModel.review_status.values,
    }),
    ...(filterModel.mapped_at &&
      filterModel.mapped_at.values.length === 1 && {
        mapped_at: {
          start_value: filterModel.mapped_at.values[0].value,
          start_option: filterModel.mapped_at.values[0].operator,
        },
      }),
    ...(filterModel.mapped_at &&
      filterModel.mapped_at.values.length === 2 && {
        mapped_at: {
          start_value: filterModel.mapped_at.values[0].value,
          start_option: filterModel.mapped_at.values[0].operator,
          end_value: filterModel.mapped_at.values[1].value,
          end_option: filterModel.mapped_at.values[1].operator,
        },
      }),
    ...(filterModel.group_ids && {
      group: {
        filter_option: filterModel.group_ids.operator,
        group_ids: filterModel.group_ids.values,
      },
    }),
    not: {
      ...(filterModel.not_revenue_category_id && {
        revenue_category_id: filterModel.not_revenue_category_id.values,
      }),
      ...(filterModel.not_mapped_by && {
        mapped_by: filterModel.not_mapped_by.values,
      }),
      ...(filterModel.description &&
        mapTextFilterParams(filterModel, 'description', 'notContains')),
      ...(filterModel.category_description &&
        mapTextFilterParams(
          filterModel,
          'category_description',
          'notContains'
        )),
      ...(filterModel.code &&
        mapTextFilterParams(filterModel, 'code', 'notContains')),
    },
    ...(startRow !== 0 && { pagination_attributes }),
    ...(pageSize && {
      page_size: gridApi.paginationGetPageSize() || 50,
    }),
    ...(sortModel && mapSortParams(sortModel)),
  };
};

const formatList = (list) => {
  if (!list?.length) {
    return '';
  }
  if (list.length === 1) {
    return list.toString();
  }
  if (list.length === 2) {
    return list.join(' and ');
  }

  return `${list.slice(0, -1).join(', ')}, and ${list.slice(-1)}`;
};

export const getActiveFiltersStatus = (filterModel) => {
  const newActiveFiltersStatus = [];
  for (const value of Object.values(filterModel)) {
    switch (value.type) {
      case 'set':
      case 'checkbox':
        newActiveFiltersStatus.push(
          `${value.label} in ${formatList(value.valueLabels)}`
        );
        break;
      case 'number':
        newActiveFiltersStatus.push(
          `${value.label} ${value.operatorLabel} ${value.value}`
        );
        break;
      case 'date':
        newActiveFiltersStatus.push(
          `${value.label} ${formatList(
            value.values.map((v) => `${v.operatorLabel} ${v.value}`)
          )}`
        );
        break;
      case 'radio':
        newActiveFiltersStatus.push(`${value.label} equals ${value.value}`);
        break;
      case 'text':
        newActiveFiltersStatus.push(
          `${value.label}${formatList(
            value.values.map((v) => ` ${v.operatorLabel} ${v.value}`)
          )}`
        );
        break;
      case 'setWithOperators':
        newActiveFiltersStatus.push(
          `${value.label} ${value.operatorLabel} ${formatList(
            value.valueLabels
          )}`
        );
        break;
    }
  }

  return newActiveFiltersStatus;
};

export const isMandatoryFilterPresent = (filterModel) => {
  return mandatoryFilters.some((filter) =>
    Object.prototype.hasOwnProperty.call(filterModel, filter)
  );
};

export const collapseInactiveFilters = (gridApi, filter = allFilters) => {
  const filterModel = gridApi.getFilterModel();
  const filtersToolPanel = gridApi.getToolPanelInstance('filters');
  filter.forEach((f) => {
    if (!Object.prototype.hasOwnProperty.call(filterModel, f)) {
      filtersToolPanel.collapseFilters([f]);
    }
  });
};

export const applyFilters = (gridApi) => {
  gridApi.onFilterChanged();
};

export const resetMappingsFilters = async (gridApi) => {
  await gridApi.setFilterModel(null);
  applyFilters(gridApi);
  gridApi.setFilterModel({
    [verified.field]: { value: 'No' },
    [has_transactions.field]: { value: 'Yes' },
  });
  gridApi
    .getToolPanelInstance('filters')
    .expandFilters([verified.field, has_transactions.field]);
};

export const resetJobMappingsFilters = async (gridApi) => {
  await gridApi.setFilterModel({
    [verified.field]: { value: 'No' },
  });
  gridApi.getToolPanelInstance('filters').expandFilters([verified.field]);
  applyFilters(gridApi);
};

export const resetJobsFilters = async (gridApi) => {
  await gridApi.setFilterModel({
    ['status']: {
      values: defaultJobsStatusFilter,
    },
  });
  gridApi.getToolPanelInstance('filters').expandFilters(['status']);
  applyFilters(gridApi);
  gridApi.closeToolPanel();
};

export const resetHistoryFilters = async (gridApi) => {
  await gridApi.setFilterModel(null);
  applyFilters(gridApi);
  gridApi.closeToolPanel();
};

export const applyFiltersOnKeyPress = (key, props) => {
  if (key === ENTER_KEY) {
    props.filterChangedCallback();
  }
};

export const getSortModel = (columnState) => {
  const column = columnState.find((col) => col.sort !== null);
  return column ? { colId: column.colId, sort: column.sort } : null;
};

export const getMappingTableFilters = (role, jobType) => {
  let filterOrderList = FILTER_ORDER_PER_TYPE[jobType];
  //if mapper move filter a bit down (not using it that often)
  if (role === MAPPER_ROLE) {
    let vssIdIndex = filterOrderList.indexOf(pms_code_vetsuccess_id.field);
    filterOrderList.splice(vssIdIndex, 1);
    let toIndex = filterOrderList.indexOf(code.field);
    filterOrderList.splice(toIndex, 0, pms_code_vetsuccess_id.field);
  }
  return filterOrderList;
};

export const mapFilterLayout = (filters) => {
  return Array.from(filters, (f) => {
    return { field: f };
  });
};
