import { errorConstants, invalidValueError } from '../constants/constants';
import { logger } from '../services/logger';

export function getError(error) {
  logger.catchError(error);
  if (error.response) {
    if (error.response.status === 400) {
      return error.response.message === 'Request Timeout' ||
        error.response.data.error === 'Request Timeout'
        ? errorConstants[400]
        : errorConstants[500];
    } else if (error.response.status === 0) {
      return errorConstants[0];
    } else if (error.response.status === 422) {
      if (
        error.response.data?.error &&
        error.response.data?.error !== 'Unprocessable Entity'
      ) {
        return { description: error.response.data.error };
      }
      return errorConstants[422];
    } else if (error.response.status === 403) {
      return errorConstants[403];
    } else {
      return errorConstants[500];
    }
  } else {
    if (error.code === 'ERR_NETWORK') {
      return errorConstants[0];
    } else if (error.message === 'Invalid value' && error.code) {
      return invalidValueError(error.code);
    }
    //send non-response related errors to sentry
    return errorConstants['client'];
  }
}

export function getJobErrorMessage(error) {
  logger.catchError(error);
  if (!error.response) {
    //send non-response related errors to sentry
    return errorConstants['client'];
  } else {
    if (error.response.status == 404) {
      return 'Job not found.';
    } else if (error.response.status == 403) {
      return 'You are not authorized for viewing this job';
    } else if (error.response.status == 500) {
      return 'Something went wrong. Please try again or report the issue.';
    } else if (error.message) {
      return error.message;
    } else {
      return 'Connection error';
    }
  }
}

export const throwInvalidValue = (value) => {
  logger.logEvent(`Failed to find value ${value}, in array`);
  let customError = new Error('Invalid value');
  customError.code = value;
  throw customError;
};
