export const MAPPER_MENU_OPTIONS = [
  { name: 'Jobs', file: 'list.svg', pathname: '/jobs' },
  { name: 'History', file: 'my_jobs.svg', pathname: '/history' },
  { name: 'My profile', file: 'user.svg', pathname: '/myprofile' },
  { name: 'Log out', file: 'log_out.svg', pathname: '/logout' },
];
export const ADMIN_MENU_OPTIONS = [
  { name: 'Mappings', file: 'Mappings.svg', pathname: '/mappings' },
  { name: 'Jobs', file: 'list.svg', pathname: '/jobs' },
  { name: 'History', file: 'my_jobs.svg', pathname: '/history' },
  { name: 'My profile', file: 'user.svg', pathname: '/myprofile' },
  { name: 'Log out', file: 'log_out.svg', pathname: '/logout' },
];

export const SUPER_ADMIN_MENU_OPTIONS = [
  { name: 'Mappings', file: 'Mappings.svg', pathname: '/mappings' },
  { name: 'Jobs', file: 'list.svg', pathname: '/jobs' },
  { name: 'History', file: 'my_jobs.svg', pathname: '/history' },
  { name: 'All users', file: 'users.svg', pathname: '/allusers' },
  { name: 'My profile', file: 'user.svg', pathname: '/myprofile' },
  { name: 'Configuration', file: 'settings.svg', pathname: '/config' },
  { name: 'Log out', file: 'log_out.svg', pathname: '/logout' },
];

export const VSO_TEAM_MENU_OPTIONS = [
  { name: 'Mappings', file: 'Mappings.svg', pathname: '/mappings' },
  { name: 'My profile', file: 'user.svg', pathname: '/myprofile' },
  { name: 'Log out', file: 'log_out.svg', pathname: '/logout' },
];

export const SAVING_STATUS = {
  saving: { label: 'Saving in progress', icon: 'sync', color: '' },
  done: { label: 'All changes saved', icon: 'check', color: 'vs-green' },
  error: { label: 'Connection lost', icon: 'sync_problem', color: 'red' },
};

export const LANDING_PAGE = {
  mapper: '/jobs',
  admin: '/mappings',
  super_admin: '/mappings',
  vso_team: '/mappings',
};

export const MENU_OPTIONS = {
  mapper: MAPPER_MENU_OPTIONS,
  admin: ADMIN_MENU_OPTIONS,
  super_admin: SUPER_ADMIN_MENU_OPTIONS,
  vso_team: VSO_TEAM_MENU_OPTIONS,
};

export const practice_id = {
  field: 'practice_id',
  columnName: 'Practice',
  tooltip: 'Practice ID',
};

export const category_description = {
  field: 'category_description',
  columnName: 'Code Category Description',
  abbreviation: 'Code C. Desc.',
};

export const code = {
  field: 'code',
  columnName: 'Code',
};

export const total_revenue = {
  field: 'total_revenue',
  columnName: 'Total Revenue',
  abbreviation: 'Tot. R.',
};

export const times_used = {
  field: 'times_used',
  columnName: 'Times Used',
  abbreviation: 'T. U.',
};

export const list_price = {
  field: 'list_price',
  columnName: 'List Price',
  abbreviation: 'L. P.',
};

export const description = {
  field: 'description',
  columnName: 'Code Description',
  abbreviation: 'Code Desc.',
};

export const non_zero_usage = {
  field: 'vacc_para_data',
  nested_field: 'Non-Zero Usage',
  columnName: 'Non-Zero Usage',
};
export const non_zero_price = {
  field: 'vacc_para_data',
  nested_field: 'Non-Zero Price',
  columnName: 'Non-Zero Price',
};

export const quantity_mode = {
  field: 'vacc_para_data',
  nested_field: 'Quantity Mode',
  columnName: 'Quantity Mode',
};

export const quantity_mode_price = {
  field: 'vacc_para_data',
  nested_field: 'Quantity Mode Price',
  columnName: 'Quantity Mode Price',
};
export const pms_code_vetsuccess_id = {
  field: 'pms_code_vetsuccess_id',
  columnName: 'PMS Code VSS ID',
  abbreviation: 'VSS ID',
};

export const free_doses = {
  field: 'free_doses',
  columnName: 'Free Doses',
  abbreviation: 'F. D.',
};

export const paid_doses = {
  field: 'paid_doses',
  columnName: 'Paid Doses',
  abbreviation: 'P. D.',
};

export const code_tags_ids = {
  field: 'code_tag_ids',
  columnName: 'Code Tags',
  abbreviation: 'C. Tags',
};

export const revenue_category_id = {
  field: 'revenue_category_id',
  columnName: 'Revenue Category',
  abbreviation: 'Rev. Cat.',
  emptyMappingValues: [null, '', '\u2014', undefined],
};

export const verified = {
  field: 'verified',
  values: [true, false, 'true', 'false'],
  columnName: 'Verified',
  abbreviation: 'V.',
};

export const review_status = {
  field: 'review_status',
  columnName: 'Review Status',
  abbreviation: 'R. S.',
  filter_values: {
    Reviewed: 'reviewed',
    'For review': 'to_be_reviewed',
  },
  values_by_key: {
    reviewed: 'Reviewed',
    to_be_reviewed: 'For review',
  },
  key_by_value: {
    Reviewed: 'reviewed',
    'For review': 'to_be_reviewed',
    '\u2014': null,
  },
  values_by_id: {
    0: 'to_be_reviewed',
    1: 'reviewed',
  },
  emptyMappingValues: [null, '', '\u2014', undefined],
  forSelect: [
    { label: '\u2014', value: '\u2014', id: null },
    { label: 'Reviewed', value: 'Reviewed', id: 'reviewed' },
    { label: 'For review', value: 'For review', id: 'to_be_reviewed' },
  ],
  filterValues: [
    { label: 'For review', value: 'to_be_reviewed' },
    { label: 'Reviewed', value: 'reviewed' },
  ],
};

export const verification_pipeline_status_id = {
  field: 'verification_pipeline_status_id',
  columnName: 'Verification Status',
  abbreviation: 'V.S.',
};

export const mapped_by = {
  field: 'mapped_by',
  columnName: 'Mapped By',
  abbreviation: 'M. by',
};

export const is_parasiticide = {
  field: 'is_parasiticide',
  columnName: 'Parasiticide',
};

export const all_diet = {
  field: 'is_diet',
  columnName: 'All Diet',
};

export const all_lab = {
  field: 'is_lab',
  columnName: 'All Lab',
};

export const all_drug = {
  field: 'is_drug',
  columnName: 'All Drug',
};

export const mapped_at = {
  field: 'mapped_at',
  columnName: 'Mapped At',
};

export const has_transactions = {
  field: 'has_transactions',
  columnName: 'Has Transactions',
};

export const not_revenue_category_id = {
  field: 'not_revenue_category_id',
  columnName: 'Not Revenue Category',
  abbreviation: 'Not Rev. Cat.',
};

export const not_mapped_by = {
  field: 'not_mapped_by',
  columnName: 'Not Mapped By',
};

export const dhpp = {
  field: 'vacc_para_data',
  nested_field: 'DHPP',
  columnName: 'DHPP',
};

export const rabies = {
  field: 'vacc_para_data',
  nested_field: 'Rabies',
  columnName: 'Rabies',
};

export const lepto = {
  field: 'vacc_para_data',
  nested_field: 'Lepto',
  columnName: 'Lepto',
};

export const lyme = {
  field: 'vacc_para_data',
  nested_field: 'Lyme',
  columnName: 'Lyme',
};

export const bordetella = {
  field: 'vacc_para_data',
  nested_field: 'Bordetella',
  columnName: 'Bordetella',
};

export const fvrcp = {
  field: 'vacc_para_data',
  nested_field: 'FVRCP',
  columnName: 'FVRCP',
};

export const felv = {
  field: 'vacc_para_data',
  nested_field: 'FeLV',
  columnName: 'FeLV',
};

export const rattlesnake = {
  field: 'vacc_para_data',
  nested_field: 'Rattlesnake',
  columnName: 'Rattlesnake',
};

export const influenza = {
  field: 'vacc_para_data',
  nested_field: 'Influenza',
  columnName: 'Influenza',
};

export const group_ids = {
  field: 'group_ids',
  columnName: 'Group',
  tooltip: 'Group',
};

export const emptyOption = {
  label: '\u2014',
  value: '\u2014',
  id: null,
};

export const VACCINE_COLUMNS = new Set([
  dhpp.nested_field,
  rabies.nested_field,
  lepto.nested_field,
  lyme.nested_field,
  bordetella.nested_field,
  fvrcp.nested_field,
  felv.nested_field,
  rattlesnake.nested_field,
  influenza.nested_field,
]);

export const PRICE_COLUMNS = [total_revenue.field, list_price.field];
export const CSV_EXPORT_COLUMNS = [
  practice_id.field,
  group_ids.field,
  pms_code_vetsuccess_id.field,
  code.field,
  times_used.field,
  total_revenue.field,
  list_price.field,
  category_description.field,
  description.field,
  non_zero_usage.columnName,
  non_zero_price.columnName,
  quantity_mode.columnName,
  quantity_mode_price.columnName,
  dhpp.columnName,
  rabies.columnName,
  lepto.columnName,
  lyme.columnName,
  bordetella.columnName,
  fvrcp.columnName,
  felv.columnName,
  rattlesnake.columnName,
  influenza.columnName,
  revenue_category_id.field,
  code_tags_ids.field,
  paid_doses.field,
  free_doses.field,
  verified.field,
  review_status.field,
  mapped_by.field,
  mapped_at.field,
];
export const DOWNLOAD_CODES_COUNT_LIMIT = 10000;

export const invalidValueError = (value) => {
  return {
    description: `Failed to find ${value} in the list. Please try setting the valid value or report the issue.`,
    title: 'Invalid value',
  };
};

export const errorConstants = {
  400: {
    description:
      'Looks like the request took too long. Try narrowing down your search and try again.',
    title: 'Request Timeout',
  },
  403: {
    description: 'You are not authorized to view and/or update this resource.',
    title: 'Forbidden Resource',
  },
  422: {
    description:
      'We were not able to process request. Try again or report the issue.',
    title: 'Unprocessable Content',
  },
  0: {
    description:
      'We were not able to connect to server. Check your internet connectivity and try again.',
    title: 'Connection Lost',
  },
  500: {
    description: 'Please try again or report the issue.',
    title: 'Something went wrong',
  },
  client: {
    description: 'Please try again or report the issue.',
    title: 'Something went wrong',
  },
};

export const USER_TABLE_COLUMNS = [
  { field: 'id', name: 'User ID' },
  { field: 'full_name', name: 'User' },
  { field: 'display_name', name: 'Display name' },
  { field: 'email', name: 'Email' },
  { field: 'role', name: 'Role' },
  { field: 'active', name: 'Active' },
];

export const MIN_PASSWORD_LENGTH = 8;
export const MAX_DISPLAY_NAME_LENGTH = 10;
export const MAX_TEXT_LENGTH = 1000;

export const allFilters = [
  pms_code_vetsuccess_id.field,
  practice_id.field,
  group_ids.field,
  description.field,
  mapped_by.field,
  revenue_category_id.field,
  not_revenue_category_id.field,
  code_tags_ids.field,
  is_parasiticide.field,
  category_description.field,
  verified.field,
  has_transactions.field,
  review_status.field,
  total_revenue.field,
  code.field,
  not_mapped_by.field,
  paid_doses.field,
  free_doses.field,
  mapped_at.field,
];

export const mandatoryFilters = [
  practice_id.field,
  group_ids.field,
  revenue_category_id.field,
  description.field,
  mapped_by.field,
  pms_code_vetsuccess_id.field,
];

export const defaultTextFilterOperators = [
  { value: 'contains', label: 'Contains' },
  { value: 'notContains', label: 'Not contains' },
];

export const jobMappingTextFilterOperators = defaultTextFilterOperators.concat([
  { value: 'equals', label: 'Equals' },
  { value: 'startsWith', label: 'Starts with' },
]);

export const defaultNumberCompareOperators = [
  { value: 'eq', label: 'Equals' },
  { value: 'lt', label: 'Less than' },
  { value: 'lte', label: 'Less than or equals' },
  { value: 'gt', label: 'Greater than' },
  { value: 'gte', label: 'Greater than or equals' },
];

export const mappingJobCompareOperators = defaultNumberCompareOperators.concat([
  {
    value: 'not',
    label: 'Is not',
  },
]);

export const CONTAINS_ALL_FILTER_VALUE = 'containsAll';
export const CONTAINS_ONLY_FILTER_VALUE = 'containsOnly';
export const CONTAINS_ANY_FILTER_VALUE = 'containsAny';
export const DOES_NOT_CONTAIN_FILTER_VALUE = 'notContains';

export const mappingJobArrayOperators = [
  { value: CONTAINS_ALL_FILTER_VALUE, label: 'Includes All' },
  { value: CONTAINS_ONLY_FILTER_VALUE, label: 'Includes Only' },
  { value: CONTAINS_ANY_FILTER_VALUE, label: 'Includes Any' },
  { value: DOES_NOT_CONTAIN_FILTER_VALUE, label: 'Does Not Include' },
];

export const GROUPS_CONTAINS_ALL_FILTER_VALUE = 'groupsContainsAll';
export const GROUPS_CONTAINS_ANY_FILTER_VALUE = 'groupsContainsAny';
export const GROUPS_DOES_NOT_CONTAIN_FILTER_VALUE = 'groupsNotContains';

export const groupsFilterOperators = [
  { value: GROUPS_CONTAINS_ALL_FILTER_VALUE, label: 'Includes All' },
  { value: CONTAINS_ONLY_FILTER_VALUE, label: 'Includes Only' },
  { value: GROUPS_CONTAINS_ANY_FILTER_VALUE, label: 'Includes Any' },
  { value: GROUPS_DOES_NOT_CONTAIN_FILTER_VALUE, label: 'Does Not Include' },
];

export const groupsFilterOperatorsServerSide = [
  { value: 'all', label: 'Includes All' },
  { value: 'only', label: 'Includes Only' },
  { value: 'any', label: 'Includes Any' },
  { value: 'not', label: 'Does Not Include' },
];

export const codeTagsFilterOperators = [
  { value: 'and', label: 'Includes All' },
  { value: 'or', label: 'Includes Any' },
];

export const digitRgx = /\d+/;

export const allDigitsRgx = /^\d+$/;

export const pageTitles = {
  '/mappings': 'Mappings',
  '/myprofile': 'My profile',
  '/history': 'History',
  '/allusers': 'All users',
  '/job': 'Job details',
  '/jobs': 'Jobs',
  '/jobmapping': 'Job Mappings',
  '/config': 'Configuration',
};

export const DELETE_KEY_CODES = [8, 46];
export const PSEUDO_EDIT_CLASS = 'pseudo-editable-cell';
export const UP_DOWN_KEY_CODES = [38, 40];
export const ARROWS_KEY_CODES = [37, 38, 39, 40];
export const ENTER_KEY = 'Enter';
export const pseudoEditableFields = [
  description.field,
  category_description.field,
];

export const AND_FILTER_TYPE = 'AND';
export const OR_FILTER_TYPE = 'OR';

export const AUTOMAPPER_ID = 1;
export const HIDDEN_MAPPED_BY_ID = -1;

export const EMAIL_PATTERN = /\S+@\S+\.\S+/;

export const PIPELINE_STATUS_TABLE_COLUMNS = [
  'ID',
  'Pipeline Status',
  'Created at',
];
export const EXCLUDED_RESOURCES_TABLE_COLUMNS = ['ID', 'Type', 'Name'];
export const INCLUDE_EXCLUDED_GROUPS_LABEL =
  'Include all Excluded Group codes and tags';
export const PARA_MODE_COLUMN_COLOR = '#dce9d580';
export const PARA_AVG_COLUMN_COLOR = '#fcf2d480';
export const VACCINE_COLUMN_COLOR = '#d9d2e980';
export const SORT_ASCENDING = 'asc';
export const SORT_DESCENDING = 'desc';

export const UNDO_REDO_EDIT_LIMIT = 20;
export const UNDO_REDO_ACTIONS = ['undo', 'redo'];
export const UNDO_REDO_ACTION = {
  undo: {
    label: 'Undo',
    macKeyboardKeys: ['\u2318', 'Z'],
    winKeyboardKeys: ['Ctrl', 'Z'],
  },
  redo: {
    label: 'Redo',
    macKeyboardKeys: ['\u2318', '\u21E7', 'Z'],
    winKeyboardKeys: ['Ctrl', 'Shift', 'Z'],
  },
};

export const GROUP_RESOURCE_TYPE = 'Group';
export const PRACTICE_RESOURCE_TYPE = 'Practice';

// In this regex pattern ^(.+?)(?:\[(\d+)\])?$:
// ^ asserts the start of the string.
// (.+?) captures one or more of any character (except for line terminators) non-greedily into group 1.
// (?:\[(\d+)\])? makes the square brackets and the digits inside them optional. (?: ... ) is a non-capturing group and ? makes the entire group optional.
// $ asserts the end of the string.
// So, match[1] will contain the text before the square brackets (or the entire string if there are no square brackets), and match[2] will contain the digits inside the square brackets if they exist.
export const regexParseNameAndId = /^(.+?)(?:\[(\d+)\])?$/;
export const findSquareBreckets = /\[.*\]/;

export const DEFAULT_PAGE_SIZE = 50;
