import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  loginUser,
  getUserDetails,
  refreshToken,
} from '../../services/userServices';
import jwt from 'jwt-decode';
import { logger } from '../../services/logger';

function getTokenState(data) {
  let decoded = jwt(data.token);
  let decodedRefresh = jwt(data.refresh_token);
  return {
    token: data.token,
    tokenExpiry: decoded.exp,
    userId: decoded.user_id,
    refreshToken: data.refresh_token,
    refreshTokenExpiry: decodedRefresh.exp,
  };
}

export const storeTokensInStorage = (data) => {
  localStorage.setItem('token', data.token);
  localStorage.setItem('refreshToken', data.refresh_token);
};

export const userLogin = createAsyncThunk(
  'user/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const { data } = await loginUser(email.trim(), password);
      storeTokensInStorage(data);
      return getTokenState(data);
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        return rejectWithValue('We were not able to find your account.');
      } else if (error.message) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('Connection error');
      }
    }
  }
);

export const refreshTokens = createAsyncThunk(
  'user/refreshTokens',
  async (arg, { rejectWithValue }) => {
    try {
      let currentRefreshToken = localStorage.getItem('refreshToken');
      const { data } = await refreshToken(currentRefreshToken);
      storeTokensInStorage(data);
      return getTokenState(data);
    } catch (error) {
      let decodedRefresh = jwt(localStorage.getItem('refreshToken'));
      logger.logEvent(`refresh tokens expiry:${Date.new(decodedRefresh.exp)}`);
      logger.catchError(error);
      return rejectWithValue('Refreshing token failed');
    }
  }
);

export const loadUserDetails = createAsyncThunk(
  'user/getUserDetails',
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { user } = getState();
      const { data } = await getUserDetails(user.userId);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const logout = createAction('logout');
export const resetState = createAction('resetState');
