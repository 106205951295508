import {
  all_diet,
  all_drug,
  all_lab,
  category_description,
  code,
  code_tags_ids,
  description,
  free_doses,
  group_ids,
  has_transactions,
  is_parasiticide,
  list_price,
  mapped_at,
  mapped_by,
  not_mapped_by,
  paid_doses,
  pms_code_vetsuccess_id,
  practice_id,
  revenue_category_id,
  review_status,
  times_used,
  total_revenue,
  verification_pipeline_status_id,
  verified,
} from './constants';
import React from 'react';

export const CUSTOM_JOB_TYPE = 'custom';
export const CLINIC_JOB_TYPE = 'clinic';
export const RATE_TYPE_PER_HOUR = 'per_hour';
export const RATE_TYPE_PER_CODE = 'per_code';

export const JOB_TYPES = {
  [CLINIC_JOB_TYPE]: { value: CLINIC_JOB_TYPE, name: 'Clinic job' },
  [CUSTOM_JOB_TYPE]: { value: CUSTOM_JOB_TYPE, name: 'Custom job' },
};

export const jobTypes = {
  [CLINIC_JOB_TYPE]: 'Practice',
  [CUSTOM_JOB_TYPE]: 'Custom',
};

export const TYPE_ORDER = {
  [CLINIC_JOB_TYPE]: 0,
  [CUSTOM_JOB_TYPE]: 1,
};

export const JOB_STATUS_CREATED = 'created';
export const JOB_STATUS_AVAILABLE = 'data_ready';
export const JOB_STATUS_IN_PROGRESS = 'in_progress';
export const JOB_STATUS_READY_TO_ONBOARD = 'ready_to_onboard';
export const JOB_STATUS_COMPLETED = 'completed';
export const JOB_STATUS_FAILED = 'failed';
export const JOB_STATUS_COMPLETION_PENDING = 'completion_pending';

export const READY_TO_ONBOARD_ACTION_LABEL = 'Mark Complete';
export const COMPLETE_JOB_ACTION_LABEL = 'Onboard';
export const DELETE_JOB_ACTION_LABEL = 'Delete';
export const INVOICE_JOB_ACTION_LABEL = 'Invoice';
export const INVOICE_MULTIPLE_JOBS_ACTION_LABEL = 'Invoice Jobs';
export const DOWNLOAD_CSV_JOB_ACTION_LABEL = 'Download CSV';
export const UNHIDE_JOB_ACTION_LABEL = 'Unhide from Mapper';
export const READY_TO_ONBOARD_JOB_PRACTICE_TYPE_MISSING_LABEL =
  'Practice Type Missing';
export const COMPLETE_JOB_PRACTICE_TYPE_MISSING_LABEL = 'Practice Type Missing';

export const COMPLETE_JOB_PRACTICE_TYPE_MISSING_TEXT =
  'This is a Clinic Job that requires entering the Practice Type. Please open Job Details modal, enter Practice Type and try completing the job again.';

export const READY_TO_ONBOARD_JOB_PRACTICE_TYPE_MISSING_TEXT =
  'This is a Clinic Job that requires entering the Practice Type. Please open Job Details modal, enter Practice Type and try completing the job again.';

export const READY_TO_ONBOARD_JOB_PRACTICE_TYPE_MISSING_WARRNING_TEXT =
  'Are you sure you want to mark this job as Complete? This is a Clinic job, which may require Practice Type input.';

export const READY_TO_ONBOARD_DIALOG_TEXT =
  'Are you sure you want to mark this job as completed? This action will submit the job to the admin for review.';

export const INVOICE_JOB_DIALOG_TEXT =
  'Are you sure you want to mark this job as invoiced?';

export const INVOICE_MUTLIPLE_JOBS_DIALOG_TEXT =
  'Are you sure you want to mark these jobs as invoiced?';

export const DOWNLOAD_CSV_JOB_DIALOG_TEXT =
  'Are you sure you want to download this job?';

export const UNHIDE_JOB_DIALOG_TEXT =
  'Are you sure you want to unhide this job from mapper?';

export const READY_TO_ONBOARD_WARNING_DIALOG_IMPORTANT_TEXT =
  'This job still has unverified codes. ';

export const READY_TO_ONBOARD_WARNING_DIALOG_TEXT =
  'Are you sure you want to mark this job as completed?';

export const READY_TO_ONBOARD_REFRESH_WARNING_DIALOG_IMPORTANT_TEXT =
  'This job has been updated and there are unverified codes left. ';

export const READY_TO_ONBOARD_REFRESH_WARNING_DIALOG_TEXT =
  'You can refresh the page to see them. Do you still want to mark it as completed?';

export const getCompleteJobDialogText = (jobId = null) => {
  return `Are you sure you want to complete the job ${
    jobId ? `#${jobId}` : ''
  }? Once completed, changes will go live.`;
};

export const getBatchInvoiceJobsResultMessage = (
  jobIds,
  noJobMetInvoiceCriteria
) => {
  if (noJobMetInvoiceCriteria) {
    return 'None of the selected jobs met the invoicing criteria and have been skipped. Please review these jobs, adjust their status and try again.';
  } else {
    if (jobIds.length > 1) {
      return (
        <>
          Jobs <b>{jobIds.join(', ')}</b> did not meet the invoicing criteria
          and have been skipped. Please review these jobs, adjust their status
          and try again.
        </>
      );
    } else {
      return (
        <>
          Job <b>{jobIds[0]}</b> did not meet the invoicing criteria and has
          been skipped. Please review it, adjust status and try again.
        </>
      );
    }
  }
};

export const getTimeSpentConfirmationDialogText = (hours) => {
  return `Reported mapped time spent for this job is ${hours}. `;
};

export const JOB_STATUSES = {
  [JOB_STATUS_CREATED]: 'Created',
  [JOB_STATUS_AVAILABLE]: 'Available',
  [JOB_STATUS_IN_PROGRESS]: 'In progress',
  [JOB_STATUS_READY_TO_ONBOARD]: 'Mapping complete',
  [JOB_STATUS_COMPLETED]: 'Completed',
  [JOB_STATUS_FAILED]: 'Failed',
  [JOB_STATUS_COMPLETION_PENDING]: 'Completion pending',
};

export const INVOICABLE_JOB_STATUSES = [
  JOB_STATUS_COMPLETED,
  JOB_STATUS_COMPLETION_PENDING,
  JOB_STATUS_READY_TO_ONBOARD,
];

export const JOB_PRIORITY_LOW = 'low';
export const JOB_PRIORITY_MEDIUM = 'medium';
export const JOB_PRIORITY_HIGH = 'high';

export const JOB_PRIORITIES = [
  { value: JOB_PRIORITY_LOW, label: 'Low' },
  { value: JOB_PRIORITY_MEDIUM, label: 'Medium' },
  { value: JOB_PRIORITY_HIGH, label: 'High' },
];

export const PRIORITY_ORDER = {
  [JOB_PRIORITY_HIGH]: 0,
  [JOB_PRIORITY_MEDIUM]: 1,
  [JOB_PRIORITY_LOW]: 2,
};

export const PRIORITIES = [
  {
    name: JOB_PRIORITY_HIGH,
    bgColor: 'bg-red-priority-bg',
    borderColor: 'border-red-priority-bg',
    textColor: 'text-red-priority-text',
  },
  {
    name: JOB_PRIORITY_MEDIUM,
    bgColor: 'bg-orange-priority-bg',
    borderColor: 'border-orange-priority-bg',
    textColor: 'text-orange-priority-text',
  },
  {
    name: JOB_PRIORITY_LOW,
    bgColor: 'bg-blue-priority-bg',
    borderColor: 'border-blue-priority-bg',
    textColor: 'text-blue-priority-text',
  },
];

export const CSV_HEADER = [
  'pms_code_vetsuccess_id',
  '"pms_code_vetsuccess_id"',
];

export const RATE_TYPES = {
  per_code: 'Per code',
  per_hour: 'Per hour',
};

export const RATE_TYPES_SELECT = [
  { value: 'per_code', label: 'Per code' },
  { value: 'per_hour', label: 'Per hour' },
];

export const RATE_TYPES_DISPLAY = {
  per_code: '/code',
  per_hour: '/hour',
};

export const priorityFiltersSelect = [
  { label: 'High', value: JOB_PRIORITY_HIGH },
  { label: 'Medium', value: JOB_PRIORITY_MEDIUM },
  { label: 'Low', value: JOB_PRIORITY_LOW },
];

export const priorityFormSelect = [
  { label: 'High', value: JOB_PRIORITY_HIGH },
  { label: 'Medium', value: JOB_PRIORITY_MEDIUM },
  { label: 'Low', value: JOB_PRIORITY_LOW },
];

export const jobStatusFiltersSelect = [
  { label: 'Created', value: JOB_STATUS_CREATED },
  { label: 'In progress', value: JOB_STATUS_IN_PROGRESS },
  { label: 'Available', value: JOB_STATUS_AVAILABLE },
  { label: 'Mapping complete', value: JOB_STATUS_READY_TO_ONBOARD },
  { label: 'Completed', value: JOB_STATUS_COMPLETED },
  { label: 'Failed', value: JOB_STATUS_FAILED },
  { label: 'Completion pending', value: JOB_STATUS_COMPLETION_PENDING },
];

export const jobStatusHistoryFiltersSelect = [
  { label: 'Completed', value: JOB_STATUS_COMPLETED },
  { label: 'Failed', value: JOB_STATUS_FAILED },
];

export const jobTypeFiltersSelect = [
  { label: 'Custom', value: 'custom' },
  { label: 'Practice', value: 'clinic' },
];

export const commonJobsFilters = [
  {
    component: 'select',
    filterType: 'priority',
    placeholderText: 'Priority',
    values: priorityFiltersSelect,
  },
  {
    component: 'select',
    filterType: 'job_type',
    placeholderText: 'Job type',
    values: jobTypeFiltersSelect,
  },
];

export const jobsFilters = [
  {
    component: 'date',
    filterType: 'available_at',
    placeholderText: 'Date available',
  },
  {
    component: 'date',
    filterType: 'claimed_at',
    placeholderText: 'Date claimed',
  },
  {
    component: 'date',
    filterType: 'completed_at',
    placeholderText: 'Date completed',
  },
  {
    component: 'select',
    filterType: 'claimed_by',
    placeholderText: 'Claimed by',
  },
  {
    component: 'select',
    filterType: 'status',
    placeholderText: 'Status',
    values: jobStatusFiltersSelect,
  },
];

export const jobsHistoryFilters = [
  {
    component: 'date',
    filterType: 'claimed_at',
    placeholderText: 'Date claimed',
  },
  {
    component: 'date',
    filterType: 'completed_at',
    placeholderText: 'Date completed',
  },
  {
    component: 'select',
    filterType: 'status',
    placeholderText: 'Status',
    values: jobStatusFiltersSelect,
  },
];

export const CLAIM_JOB_ACTION = 'claim';
export const DOWNLOAD_CSV_ACTION = 'download_csv';
export const READY_TO_ONBOARD_JOB_ACTION = 'ready_to_onboard';
export const COMPLETE_JOB_ACTION = 'complete';
export const READY_TO_ONBOARD_WARNING_JOB_ACTION =
  'ready_to_onboard_with_warning';
export const DELETE_JOB_ACTION = 'delete';
export const INVOICE_JOB_ACTION = 'invoice';
export const UNHIDE_JOB_ACTION = 'unhide';
export const INVOICE_MULTIPLE_JOBS_ACTION = 'invoice_jobs';
export const READY_TO_ONBOARD_PRACTICE_TYPE_WARNING_ACTION =
  'ready_to_onboard_with_practice_type_warning';
export const COMPLETE_JOB_PRACTICE_TYPE_MISSING_WARNING_ACTION =
  'complete_with_practice_type_warning';
export const READY_TO_ONBOARD_PRACTICE_TYPE_MISSING_NOT_ALLOWED_ACTION =
  'cannot_ready_to_onboard_practice_type_missing';
export const COMPLETE_JOB_PRACTICE_TYPE_MISSING_NOT_ALLOWED_ACTION =
  'cannot_complete_practice_type_missing';

export const jobsTableCapitalizedLetters = [
  'job_type',
  'project_tracking',
  'admin_description',
];

export const defaultJobMappingFilters = [
  practice_id.field,
  group_ids.field,
  revenue_category_id.field,
  description.field,
  pms_code_vetsuccess_id.field,
  mapped_by.field,
  not_mapped_by.field,
  code_tags_ids.field,
  is_parasiticide.field,
  all_diet.field,
  all_lab.field,
  all_drug.field,
  category_description.field,
  verified.field,
  has_transactions.field,
  review_status.field,
  total_revenue.field,
  times_used.field,
  list_price.field,
  code.field,
  paid_doses.field,
  free_doses.field,
  mapped_at.field,
];

export const CLINIC_JOB_FILTER_ORDER = defaultJobMappingFilters;
export const CUSTOM_JOB_FILTER_ORDER = [
  ...CLINIC_JOB_FILTER_ORDER.slice(0, 15),
  verification_pipeline_status_id.field,
  ...CLINIC_JOB_FILTER_ORDER.slice(15),
];

export const FILTER_ORDER_PER_TYPE = {
  [CLINIC_JOB_TYPE]: CLINIC_JOB_FILTER_ORDER,
  [CUSTOM_JOB_TYPE]: CUSTOM_JOB_FILTER_ORDER,
};

export const jobsPageFilters = [
  'priority',
  'job_type',
  'project_tracking',
  'status',
  'claimed_by_formatted',
  'invoiced',
  'claimed_at',
  'completed_at',
  'available_at',
];

export const jobsPageFiltersMapper = [
  'priority',
  'job_type',
  'project_tracking',
  'status',
  'invoiced',
  'claimed_at',
  'completed_at',
  'available_at',
];

export const jobsHistoryPageFilters = [
  'priority',
  'job_type',
  'project_tracking',
  'status',
  'invoiced',
  'claimed_at',
  'completed_at',
];

export const activeJobStatuses = [
  JOB_STATUS_CREATED,
  JOB_STATUS_AVAILABLE,
  JOB_STATUS_IN_PROGRESS,
  JOB_STATUS_READY_TO_ONBOARD,
];

export const viewableJobStatuses = [
  JOB_STATUS_AVAILABLE,
  JOB_STATUS_IN_PROGRESS,
  JOB_STATUS_READY_TO_ONBOARD,
];
export const mappableJobStatuses = [
  JOB_STATUS_IN_PROGRESS,
  JOB_STATUS_READY_TO_ONBOARD,
];

export const inactiveJobStatuses = [JOB_STATUS_FAILED, JOB_STATUS_COMPLETED];

export const jobsErrorConstants = {
  claimJob: {
    description: 'This job has already been claimed.',
    title: 'Forbidden Resource',
  },
  jobAlreadyCreated: {
    description: 'There is already an active job for this clinic.',
    title: 'Job exists',
  },
  // what should be the message for these 2?
  customJobCsvInvalid: {
    description:
      'Invalid file format, make sure the file is correctly formatted.',
    title: 'Invalid file',
  },
  customJobCreatedWithFailedStatus: {
    description: 'Job has been created with status failed.',
    title: 'Failed',
  },
  completedJobCsv: {
    // eslint-disable-next-line quotes
    description: "Old completed jobs can't be downloaded.",
    title: 'Failed',
  },
};

export const defaultJobsStatusFilter = [
  { label: 'Created', value: JOB_STATUS_CREATED },
  { label: 'In progress', value: JOB_STATUS_IN_PROGRESS },
  { label: 'Available', value: JOB_STATUS_AVAILABLE },
  { label: 'Mapping complete', value: JOB_STATUS_READY_TO_ONBOARD },
];

export const UPDATE_WAITING_TIME_LIMIT = 1000;

export const JOBS_CSV_EXPORT_COLUMNS = [
  'project_tracking',
  'id',
  'practice_id',
  'job_type',
  'admin_description',
  'mapped_code_count',
  'rate',
];

export const CLINIC_JOBS_PRACTICE_TYPES = [
  { label: 'Small Animal Wellness', value: 'Small Animal Wellness' },
  { label: 'Farm Animal Wellness', value: 'Farm Animal Wellness' },
  { label: 'Exotics', value: 'Exotics' },
  { label: 'Equine', value: 'Equine' },
  { label: 'Emergency', value: 'Emergency' },
  { label: 'Specialty', value: 'Specialty' },
  { label: 'Feline', value: 'Feline' },
  { label: 'Vaccine/Wellness Exclusive', value: 'Vaccine/Wellness Exclusive' },
  { label: 'Mobile', value: 'Mobile' },
  { label: 'Urgent Care', value: 'Urgent Care' },
  { label: 'Spay/Neuter Clinic', value: 'Spay/Neuter Clinic' },
  { label: 'Shelter Medicine', value: 'Shelter Medicine' },
];
