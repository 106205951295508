export const COMPLETE_JOB_MODAL = 'completeJobModal';
export const ONBOARD_JOB_MODAL = 'onboardJobModal';
export const ADD_CODE_TAGS_MODAL = 'addCodeTagsModal';
export const REPLACE_CODE_TAGS_MODAL = 'replaceCodeTagsModal';
export const REMOVE_CODE_TAGS_MODAL = 'removeCodeTagsModal';
export const UPDATE_REVENUE_CATEGORY_MODAL = 'updateRevenueCategoryModal';
export const UPDATE_FREE_DOSES_MODAL = 'updateFreeDosesModal';
export const UPDATE_PAID_DOSES_MODAL = 'updatePaidDosesModal';
export const UPDATE_REVIEW_STATUS_MODAL = 'updateReviewModal';
export const UPDATE_VERIFICATION_STATUS_MODAL = 'updateVerificationStatusModal';
export const INVOICE_JOB_MODAL = 'invoiceJobModal';
export const UNHIDE_JOB_MODAL = 'unhideCSVModal';
export const JOB_DETAILS_MODAL = 'jobDetailsModal';
export const EDIT_JOB_DETAILS_MODAL = 'editJobDetailsModal';
export const ONBOARD_JOB_NOT_ALLOWED_MODAL = 'onboardJobNotAllowedModal';
export const ONBOARD_JOB_PRACTICE_TYPE_WARNING_MODAL =
  'onboardJobPracticeTypeWarningModal';
export const COMPLETE_JOB_NOT_ALLOWED_MODAL = 'completeJobNotAllowedModal';
export const COMPLETE_JOB_PRACTICE_TYPE_WARNING_MODAL =
  'completeJobPracticeTypeWarningModal';
