import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import OutlineButton from '../../common/OutlineButton';
import React from 'react';
import ColoredButton from '../../common/ColoredButton';
import { useSelector } from 'react-redux';
import { verified } from '../../../constants/constants';
import { getMappableElements } from '../../../helpers/selectHelper';

export default function UpdateRevenueCategoryForm({
  onCancel,
  onSubmitHandler,
}) {
  const { buttonsDisabled, serverError } = useSelector((state) => state.modal);
  const { allRevenueCategories } = useSelector((state) => state.tableData);

  const onSubmit = (data) => {
    let params = {
      revenue_category_id: data.newRevenueCategory.id,
      batch_action: 'batch revenue_category update',
    };
    if (data.verify) {
      params[verified.field] = 'true';
    }
    onSubmitHandler(params);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div translate="no" className={'text-13 my-6 px-1'}>
        <Controller
          name="newRevenueCategory"
          control={control}
          rules={{ required: true }}
          aria-invalid={errors.newRevenueCategory ? 'true' : 'false'}
          render={({ field }) => (
            <Select
              {...field}
              isDisabled={buttonsDisabled}
              options={getMappableElements(allRevenueCategories)}
            />
          )}
        />
        {errors.newRevenueCategory?.type === 'required' && (
          <p className="absolute text-red-500 text-center w-60 my-1 text-xs">
            Revenue Category is required
          </p>
        )}
      </div>
      <div className={'text-13 my-4 px-1'}>
        <input
          type="checkbox"
          value=""
          id="verifyCodesChecked"
          disabled={buttonsDisabled}
          {...register('verify')}
          className="m-2 border border-gray rounded-sm checked:bg-blue-600 checked:border-blue-600"
        />
        <label htmlFor="verifyCodesChecked" className="text">
          Verify selected codes
        </label>
      </div>
      <p className="text-red-500 text-center h-8 w-60 my-1 text-xs">
        {serverError}
      </p>
      <OutlineButton
        text="Cancel"
        heightClass="h-10"
        onClick={onCancel}
        disabled={buttonsDisabled}
      />
      <ColoredButton
        text="OK"
        heightClass="h-10"
        submit={true}
        disabled={buttonsDisabled}
      />
    </form>
  );
}
