import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { updateJobDetails } from '../../../services/jobServices';
import OutlineButton from '../../common/OutlineButton';
import ColoredButton from '../../common/ColoredButton';
import { getError } from '../../../helpers/errorHelper';
import {
  JOB_PRIORITIES,
  RATE_TYPES_SELECT,
  inactiveJobStatuses,
} from '../../../constants/jobConstants';
import { jobDetailsEditCanceled } from '../../../features/modal/modalSlice';
import { jobDetailsEditSaved } from '../../../features/job/jobActions';
import { MAX_TEXT_LENGTH } from '../../../constants/constants';
import { isClinicJob } from '../../../helpers/jobsHelper';
import NumberInputWithoutScroll from '../../common/NumberInputWithoutScroll';

export default function EditJobForm() {
  const dispatch = useDispatch();
  const [serverError, setServerError] = useState(null);
  const { jobDetailsModalData } = useSelector((state) => state.modal);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onCancel = () => {
    dispatch(jobDetailsEditCanceled(jobDetailsModalData));
  };

  const isTheFieldReadOnly = useCallback(() => {
    return inactiveJobStatuses.includes(jobDetailsModalData.status);
  }, [jobDetailsModalData]);

  const submitForm = (data) => {
    data.priority = data.priority.value;
    data.rate_type = data.rate_type.value;
    updateJobDetails(jobDetailsModalData.id, data)
      .then(({ data }) => {
        dispatch(jobDetailsEditSaved(data));
      })
      .catch((error) => {
        setServerError(getError(error).description);
      });
  };
  return (
    <>
      <form
        id="editJobForm"
        className="mt-6"
        onSubmit={handleSubmit(submitForm)}
      >
        <>
          <div className="relative mt-3 mb-12">
            <Controller
              name="priority"
              id="priority"
              control={control}
              onChange={([selected]) => {
                return { value: selected };
              }}
              defaultValue={JOB_PRIORITIES.find((p) => {
                return p.value === jobDetailsModalData.priority;
              })}
              render={({ field }) => (
                <Select
                  {...field}
                  id="priority"
                  className="formInput select z-20"
                  options={JOB_PRIORITIES}
                  isDisabled={isTheFieldReadOnly()}
                />
              )}
            />
            <label htmlFor="priority" className="formLabel">
              Priority
            </label>
          </div>
          {isClinicJob(jobDetailsModalData) && (
            <>
              <div className="relative mt-3 mb-8">
                <NumberInputWithoutScroll
                  id="salesforce_case_number"
                  label="Salesforce Case Number"
                  registerProps={register(
                    'salesforce_data.salesforce_case_number',
                    {
                      disabled: isTheFieldReadOnly(),
                    }
                  )}
                  className="formInput peer"
                  defaultValue={
                    jobDetailsModalData.salesforce_data?.salesforce_case_number
                  }
                />
              </div>
              <div className="relative mt-3 mb-8">
                <NumberInputWithoutScroll
                  id="onboarding_category"
                  label="Onboarding Category"
                  registerProps={register(
                    'salesforce_data.onboarding_category',
                    {
                      disabled: isTheFieldReadOnly(),
                    }
                  )}
                  className="formInput peer"
                  defaultValue={
                    jobDetailsModalData.salesforce_data?.onboarding_category
                  }
                />
              </div>
            </>
          )}
          <div className="relative mt-3 mb-8">
            <input
              id="project-tracking"
              type="text"
              className="formInput peer"
              defaultValue={jobDetailsModalData.project_tracking}
              {...register('project_tracking', {
                disabled: isTheFieldReadOnly(),
              })}
            />
            <label htmlFor="project-tracking" className="formLabel">
              Project Tracking
            </label>
          </div>
          <div className="relative mt-3 mb-8">
            <NumberInputWithoutScroll
              id="rate"
              label="Rate ($)"
              registerProps={register('rate', {
                required: true,
              })}
              className="formInput peer"
              step="0.01"
              defaultValue={jobDetailsModalData.rate}
            />
            {errors.rate?.type === 'required' && (
              <p className="absolute text-red-500 w-60 mt-1 text-xs">
                Rate is required
              </p>
            )}
          </div>
          <div className="relative mt-3 mb-8">
            <Controller
              name="rate_type"
              id="rate-type"
              control={control}
              onChange={([selected]) => {
                return { value: selected };
              }}
              defaultValue={RATE_TYPES_SELECT.find((p) => {
                return p.value === jobDetailsModalData.rate_type;
              })}
              render={({ field }) => (
                <Select
                  {...field}
                  id="rate-type"
                  className="formInput select z-20"
                  options={RATE_TYPES_SELECT}
                  isDisabled={isTheFieldReadOnly()}
                />
              )}
            />
            <label htmlFor="rate-type" className="formLabel">
              Rate Type
            </label>
          </div>
          <div id="dateInputContainer" className="relative mb-8">
            <Controller
              name="available_at"
              id="available_at"
              control={control}
              defaultValue={new Date(jobDetailsModalData.available_at)}
              render={({ field }) => (
                <DatePicker
                  className="formInput"
                  wrapperClassName="formDatepicker"
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => field.onChange(date)}
                  selected={new Date(field.value)}
                  readOnly={isTheFieldReadOnly()}
                />
              )}
            />
            <label htmlFor="available_at" className="formLabel">
              Date Available
            </label>
          </div>
          <div className="relative mb-8">
            <textarea
              id="admin_description"
              rows="4"
              className="textarea form text-sm peer relative top-3 border resize-none"
              defaultValue={jobDetailsModalData.admin_description}
              {...register('admin_description', {
                maxLength: MAX_TEXT_LENGTH,
                disabled: isTheFieldReadOnly(),
              })}
            />
            <label htmlFor="admin_description" className="formLabel">
              Description
            </label>
            {errors.admin_description?.type === 'maxLength' && (
              <p className="absolute text-red-500 w-80 mt-3 text-xs">
                Admin description can have up to {MAX_TEXT_LENGTH} characters
              </p>
            )}
          </div>
          <div className="relative mt-3 mb-8">
            <textarea
              id="admin_notes"
              rows="4"
              className="textarea form text-sm peer relative top-3 border resize-none"
              defaultValue={jobDetailsModalData.admin_notes}
              {...register('admin_notes', {
                maxLength: MAX_TEXT_LENGTH,
              })}
            />
            <label htmlFor="admin_notes" className="formLabel">
              Admin notes:
            </label>
            {errors.admin_notes?.type === 'maxLength' && (
              <p className="absolute text-red-500 w-80 mt-3 text-xs">
                Admin notes can have up to {MAX_TEXT_LENGTH} characters
              </p>
            )}
          </div>
          <div className="relative mt-3 mb-3">
            <div className="formSectionTitle">Protocol:</div>
            <div className="formSection">
              <div className="relative w-[30%]">
                <label htmlFor="protocolDhpp" className="formLabel">
                  DHPP
                </label>
                <input
                  id="protocolDhpp"
                  type="text"
                  className="formInput inline"
                  defaultValue={jobDetailsModalData.vaccine_protocol?.dhpp}
                  {...register('vaccine_protocol.dhpp', {
                    disabled: isTheFieldReadOnly(),
                  })}
                />
              </div>
              <div className="relative w-[30%]">
                <label htmlFor="protocolFvrcp" className="formLabel">
                  FVRCP
                </label>
                <input
                  id="protocolFvrcp"
                  type="text"
                  className="formInput inline"
                  defaultValue={jobDetailsModalData.vaccine_protocol?.fvrcp}
                  {...register('vaccine_protocol.fvrcp', {
                    disabled: isTheFieldReadOnly(),
                  })}
                />
              </div>
              <div className="relative w-[30%]">
                <label htmlFor="protocolRabies" className="formLabel">
                  Rabies
                </label>
                <input
                  id="protocolRabies"
                  type="text"
                  className="formInput inline"
                  defaultValue={jobDetailsModalData.vaccine_protocol?.rabies}
                  {...register('vaccine_protocol.rabies', {
                    disabled: isTheFieldReadOnly(),
                  })}
                />
              </div>
            </div>
          </div>
        </>
        <div className="h-4 w-full mb-4 text-center text-red-500 text-xs">
          {serverError}
        </div>
        <div className="text-center">
          <OutlineButton
            outlineColor="border-gray"
            textColor="text-gray"
            text="Cancel"
            widthClass="w-[120px]"
            heightClass="h-10"
            onClick={onCancel}
          />
          <ColoredButton
            fillColor="bg-vs-green"
            text="Save"
            widthClass="w-[120px]"
            heightClass="h-10"
            submit={true}
          />
        </div>
      </form>
    </>
  );
}
