import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../images/mapping_site_logo.png';
import { userLogin } from '../features/user/userActions';
import { useForm } from 'react-hook-form';
import LinkButton from './common/LinkButton';

export default function Login() {
  const { register, handleSubmit } = useForm();
  const { loading, error } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const submitForm = (data) => {
    dispatch(userLogin(data));
  };

  return (
    <div
      id="pageContainer"
      className="h-full w-full flex items-center justify-center bg-bgGreen"
    >
      <div
        id="loginWrapper"
        className="w-full max-w-md shadow-basic rounded-sm login-form bg-white"
      >
        <img className="mx-auto w-16 h-14" src={logo} alt="Mapping App" />
        <div id="line" className="border-b-2 mt-3" />
        <h1 className="mt-3 text-center font-light text-lg text-dark-gray">
          Welcome Back
        </h1>
        <form className="mt-6" onSubmit={handleSubmit(submitForm)}>
          <div className="relative">
            <input
              id="email"
              type="email"
              className="pb-1.5  pl-0 peer placeholder-transparent
                mt-0 block w-full px-0.5
                border-0 border-b-2 border-darker-gray
                focus:ring-0 focus:border-black"
              placeholder="Email"
              {...register('email')}
              required
            />
            <label
              htmlFor="email"
              className="absolute -top-3.5
                transition-all
                text-darker-gray
                peer-placeholder-shown:text-base
                peer-placeholder-shown:top-3
                peer-focus:-top-3.5
              "
            >
              Email
            </label>
          </div>
          <div className="relative mt-3">
            <input
              id="password"
              type="password"
              className="pb-1.5 pl-0 peer placeholder-transparent
                mt-0 block w-full px-0.5
                border-0 border-b-2 border-darker-gray
                focus:ring-0 focus:border-black"
              placeholder="Password"
              {...register('password')}
              required
            />
            <label
              htmlFor="password"
              className="absolute -top-3.5
                transition-all
                text-darker-gray
                peer-placeholder-shown:text-base
                peer-focus:-top-3.5
                peer-placeholder-shown:top-3"
            >
              Password
            </label>
          </div>
          {error ? (
            <label className="absolute text-red-500 text-xs">{error}</label>
          ) : null}
          <div className="text-center">
            <button
              disabled={loading}
              className="text-13 py-1 mx-1 px-3 rounded-sm h-10 mt-7 bg-purple-vs font-medium text-white w-[100px] hover:bg-purple-vs-hover"
              type="submit"
            >
              Login
            </button>
          </div>
          <div className="mt-7 text-center">
            <LinkButton
              fontSize="text-15"
              fontWeight="font-light"
              text="Forgot your password?"
              navigation="/resetPassword"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
